import React from 'react';
import { Link} from "react-router-dom";
import { config } from '../Configurator';
import './../components/CssCustomizer';
import CssCustomizer from './../components/CssCustomizer';

const Navbar = () => {

    return(
        <nav className={ 'navbar navbar-expand-lg ' + (config().navbarClass==='white' ? 'navbar-light bg-white' : 'navbar-dark bg-dark') }>
                    <CssCustomizer></CssCustomizer>

        <div className="container">
            <Link to="/dash" className="navbar-brand">
                <img src={ config().logo } height="26" alt="Logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
        
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">

                {/* Master Nav */}
                <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" /></Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/prospects"><img src={'/images/' + config().navIconClass + '/person_add-24px.svg'} height="26" alt="Prospects / Customers" /></Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/customers"><img src={'/images/' + config().navIconClass + '/person-24px.svg'} height="26" alt="Prospects / Customers" /></Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/cases"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Cases" /></Link></li>



                {/* Reward nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" /></Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/casesprospect"><img src={'/images/' + config().navIconClass + '/person_add-24px.svg'} height="26" alt="Prospects" /></Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/cases"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Cases" /></Link></li> */}


            </ul>
            <ul className="navbar-nav float-right">
                <li className="nav-item dropdown float-right">
                    <a className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* <img src={ config().personProfileImage } className="float-left img-round mr-2" height="41" /> */}
                        { config().personName }<br />
                        <small>{ config().personFunction }</small>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {/* <Link className="dropdown-item" to="/proposal">Proposal Form</Link> */}
                        {/* <Link className="dropdown-item" to="/dynamicprop">Proposal Form (API)</Link> */}
                        {/* <Link className="dropdown-item" to="/form/3">Proposal Form (API) with steps</Link> */}
                        {/* <Link className="dropdown-item" to="/articles/index">Articles</Link> */}
                        {/* <div className="dropdown-divider"></div> */}

                        {config().nav_links.map((value, index) => {
                            return <Link key={index} className="dropdown-item" to={value[0]}>{value[1]}</Link>
                        })}

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/logout">Logout</Link>
                    </div> 
                </li>
            </ul>
            </div>
        </div>
      </nav>
        );

}

export default Navbar;