import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, orderResult } from '../Utils';
import Loading from '../components/Loading';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class ProspectsTable extends Component {

    constructor(props) {
        super(props)
        this.userChange = this.userChange.bind(this);
        this.state = {
            loading: true,
            user: 'all',
            users: [],
            customers: [],
            job_states: [],
            job_state: ''
        } 
    }

    userChange(event) {
        // console.log(event.target.value);
        this.setState({'user': event.target.value});
        // let {name: fieldName, value} = event.target;
        // this.load(value);
    }

    loadCustomers() {
        this.load(null);
    }

    load(user) {
        // if(user===null) user = localStorage.getItem('bo_email');
        this.setState({'loading': true});
        axios.post(config().base_url + 'GetCustomerListForUserAndCustomerTypeSortOrderJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "SearchOptions": {
              "SortType": "Decending",
              "searchText": "",
              "isTelesalesRequest": "true",
              "endDate": "",
              "searchType": "Owner",
              "SortBy": "Code",
              "customerTypeCode": "",
              "customerStatusCode": "Prospect",
              "recordStartNo": "0",
              "customerCode": "",
              "startDate": "",
              "recordEndNo": "10000"
            }
          })
        .then(response => {

            let owner_names = [];
            for(let i=0; i<response.data.d.Records.length; i++) {
                let new_name = response.data.d.Records[i]['OwnerName'];
                if(new_name && !owner_names.includes(new_name)) owner_names.push(new_name);
            }

            let job_states = [];
            for(let i=0; i<response.data.d.Records.length; i++) {
                let new_state = response.data.d.Records[i]['CustomerStatus'];
                if(new_state && !job_states.includes(new_state)) job_states.push(new_state);
            }

            this.setState({ job_states: job_states, customers: orderResult(response.data.d.Records, "CustomerReference", "desc"), users: owner_names.sort(), loading: false});


        })
        .catch(error => {
            this.setState({loading: false});
            console.log(error);
        });
    }

    componentDidMount() {
        this.loadCustomers();
    }

    render() {
        const { customers, loading, users, user, job_state, job_states } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8"><h4 className="font-weight-bold">Prospects</h4></div>
                    <div className="col-md-2">
                        <select style={{ maxWidth: '100%' }} className="filters" value={this.state.job_state} onChange={(e) => this.setState({job_state: e.target.value}, function(){ this.load() })}>
                            <option value="">Any Status</option>
                            {this.state.job_states.map((v) => <option key={v} value={v}>{v}</option>)}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select id="user" onChange={this.userChange} className="filters" value={this.state.user}>
                            <option value="all"></option>
                            <option value="">Current User</option>
                            { users.map((e, key) => {
                                return <option key={e} value={e}>{e}</option>;
                            })}
                        </select>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <table className="table">
                                <thead>
                                <tr>
                                    <th width="10%">Reference</th>
                                    <th width="10%">Created</th>
                                    <th width="20%">Company</th>
                                    <th width="20%">Name</th>
                                    <th width="20%">Address</th>
                                    <th width="10%">Status</th>
                                    <th width="10%"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (customers.length) ?
                                    customers.map(entry => {
                                        return ((user && entry.OwnerName==user) || (user && user=='all') || (!user && entry.OwnerName==config().personName )) && (job_state && entry.CustomerStatus===job_state || !job_state) ?
                                         <tr key={entry.CustomerReference}>
                                            <td>{ entry.CustomerReference }</td>
                                            <td>{ convertDate(entry.DateCreated) }</td>
                                            <td>{ entry.CompanyName }</td>
                                            <td>{ entry.Firstname } { entry.Surname }</td>
                                            <td>{ entry.CustomerAddress.Address1 } { entry.CustomerAddress.PostCode }</td>
                                            <td>{ entry.CustomerStatus }</td>
                                            <td>
                                                <Link to={ '/prospect/' + entry.CustomerReference } className="btn-more"></Link>
                                            </td>
                                        </tr> : null
                                    }) : 
                                    null
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default ProspectsTable;