import React from 'react';
 
function Loading() {
  // const greeting = 'Hello Function Component!';
 
  return (
      <div>
          <div className="loader"></div>
      </div>
  );
}
 
export default Loading; 