import { config } from './Configurator'
import { convertToDouble /*, currency, pmt_calc */ } from './Utils';

export const modCalculateEquipValue = (att) => 
{
  console.log('executing modCalculateEquipValue');
  att.rrp = 0;
  att.net_price = 0;
  att.not_vatable_cost = 0;
  att.road_fund_licence = 0;
  att.total_cost = 0;
  for(let j=0; j<20; j++) {
      if(typeof att['total_cost_2__' + j] != 'undefined') {
          att.rrp += convertToDouble(att['rrp__' + j]);
          att.net_price += convertToDouble(att['net_price__' + j]);
          // eq_vat += convertToDouble(att['vat__' + j]);
          att.not_vatable_cost += convertToDouble(att['not_vatable_cost__' + j]);
          att.road_fund_licence += convertToDouble(att['road_fund__' + j]);
          att.total_cost += convertToDouble(att['total_cost_2__' + j]);
      }
  }
  console.log("EQ sums rrp=" + att.rrp +", net_price=" + att.net_price + ", not_vatable_cost=" + att.not_vatable_cost + ", total_cost=" + att.total_cost)
  return att;    
}
