import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router} from "react-router-dom";
import Home from './Pages/Home';
import Cases from './Pages/Cases';
import CasesProspect from './Pages/CasesProspect';
import Case from './Pages/Case';
import Login from './Pages/Login';
import Dynamic from './Pages/Dynamic';
import DynamicFormApiWithSteps from './Pages/DynamicFormApiWithSteps';
import Thanks from './Pages/Thanks';

import ArticleIndex from './Pages/ArticleEditor/ArticleIndex';
import ArticleEdit from './Pages/ArticleEditor/ArticleEdit';
import Customer from './Pages/Customer';
import Customers from './Pages/Customers';
import Prospect from './Pages/Prospect';
import Prospects from './Pages/Prospects';
import Courses from './Pages/Courses';
import Delegates from './Pages/Delegates';

function App() {

  // let history = useHistory();
  return (
    <Router>
    <div className="App">
    </div>

    <Route path="/" exact component={Login} />
    <Route path="/dash" exact component={Home} />
    <Route path="/case/:id" exact component={Case} />
    <Route path="/cases" exact component={Cases} />
    <Route path="/casesprospect" exact component={CasesProspect} />

    <Route path="/courses" exact component={Courses} />
    <Route path="/delegates" exact component={Delegates} />

    <Route path="/customers" exact component={Customers} /> 
    <Route path="/customer/:id" exact component={Customer} /> 

    <Route path="/prospects" exact component={Prospects} /> 
    <Route path="/prospect/:id" exact component={Prospect} /> 


    <Route path="/proposal" exact component={Dynamic} />
    
    {/* <Route path="/dynamicprop" exact component={DynamicFormApi}  // deprecated */}
    
    <Route path="/form/:id" exact component={DynamicFormApiWithSteps} />
    <Route path="/form/:id/:step" exact component={DynamicFormApiWithSteps} />
    <Route path="/form/:id/:step/:applicant" exact component={DynamicFormApiWithSteps} />

    <Route path="/thanks/:reference?" exact component={Thanks} />

    <Route path="/articles/index" exact component={ArticleIndex} />
    <Route path="/articles/edit/:id" exact component={ArticleEdit} />

    <Route path="/logout" exact component={Login} />

    </Router>
  );
}

export default App;
