import React from 'react';

const entries = [];

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : entries.filter(country =>
        country.label.toLowerCase().slice(0, inputLength) === inputValue
    );
};

export const getSuggestionValue = ( formikProps, config, extra, { label } ) => label;
export const renderSuggestion = ( formikProps, config, extra, { label } ) => <span>{ label }</span>;
// export const onSuggestionsFetchRequested = ( formikProps, config, { stateUpdater }, { value } ) => ({ suggestions: getSuggestions(value) });


export const onSuggestionsFetchRequested = ({value}) => {

    getSuggestions = (value, (err, data) => {
      this.setState({isLoading: false,  suggestions: data});
    });
  
  }

export const onSuggestionsClearRequested = ( formikProps, config, extra ) =>
    extra.stateUpdater({ suggestions: [] })