import axios from 'axios';
import { config } from '../Configurator'
import { convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "UpdateCase";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeUpdateCaseLend = (att, applicant_id, customer_reference) => {

    if(applicant_id===null) {
        alert("Applicant ID can't be null");
        return false;
    }

    console.log('passed customer reference ==' + customer_reference);

    // #1 Update case data 
    axios.post(config().base_url + endpoint, updateCasePayload(att, applicant_id, customer_reference)).then(function(response){
        let value = '';
        if(response.data.d.UpdateResult.SuccessCode==0) {

            // #2 if update goes well, get case assessor 

            value = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            if(att.form_id=='13') { // delay for sound to play 
                                setTimeout(function(){ window.location.href = "/case/" + applicant_id;  }, 2800);
                            } else {
                                window.location.href = "/case/" + applicant_id;
                            }
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });

            // window.location.href = "/thanks/" + response.data.d.Value;
        }
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });




}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "IsUpdateCaseCustomer":true,
            "MainApplicant": {
                "Title": att.Title,
                "Firstname": att.FirstName,
                "Surname": att.Surname,
                "DateOfBirth": convertDateForApi("att.DateOfBirth"),
                "Telephone": att.Telephone,
                "WorkTelephone": '',
                "Mobile": att.Mobile,
                "EmailAddress": att.Email,
                "CurrentAddress": {
                    "HouseName": '',
                    "HouseNumber": '',
                    "Address1": att.Address1,
                    "Address2": att.Address2,
                    "Address3": att.Address3,
                    "Address4": att.Address4,
                    "PostCode": att.Postcode
                },
                "PreviousAddresses": {},
                "App2ID": 0
            },
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "NetClaim": att.NetClaim,
            "Commission": att.Commission,
            "ClaimInterest": att.ClaimInterest,
            "CustomerInitialFee": att.CustomerInitialFee,
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": att.CustomerCompletionFee,
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi(""),
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',
            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.purpose
            },
            {
                "FieldNo": 3,
                "FieldValue": att.dynamicData3
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],
            /*
            "SecurityAddress": [
                {
                    "Code": "",
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att.CurrentValue,
                    "SecuirtyType": att.secuirtyType,
                    "SecuirtyDetails": att.secuirtyDetails,
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att.SecuirtyChargeValue,
                    "SecuirtyChargeDate": null,
                    "SecuirtyEquityValue": att.SecuirtyEquityValue,
                    "SecuirtyLendingValue": att.SecuirtyLendingValue,
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": '',
                    "SecuirtyMortgageValue": 0,
                    "SecuirtyMarketValue": 0,
                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
            ],
            */
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 
    for(let i=0; i<100; i++) {
        if(typeof att['SecuirtyCode__' + i] !=='undefined') {
            console.log(att['SecuirtyCode__' + i]);

            ret.CaseApp.SecurityAddress.push(
                {
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['secuirtyType__' + i],
                    "SecuirtyDetails": att['secuirtyDetails__' + i],
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(null),
                    "SecuirtyEquityValue": att['SecuirtyEquityValue__' + i],
                    "SecuirtyLendingValue": att['SecuirtyLendingValue__' + i],
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": convertDateForApi(''),
                    "SecuirtyMortgageValue": 0,
                    "SecuirtyMarketValue": Number(att['security_multiplier__' + i]),
                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeUpdateCaseLend
};
