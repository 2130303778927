import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "CreateNewProspectV2";

export const executeCreateNewProspectV2Peregrine = (att) => {
    
    let payload = {
        "userInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "customer": {
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "CompanyName": att.CompanyName,
            "Telephone": att.telephone,
            "Email": att.email,
            "Mobile": att.mobile,
            "Website": att.companyreg,
            "Notes": att.proposal_notes,
            "DateOfBirth": convertDateForApi(""),
            "CustomerStatus": "Prospect",
            "CustomerGroup": att.CustomerGroup,
            "B2BCustomer": "true",
            "AltSalutation": att.AltSalutation,
            "InvoiceTermName":"",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.Address1,
                "Address2": att.Address2,
                "Address3": att.Address3,
                "Address4": att.Address4,
                "PostCode": att.PostCode
            },
            "CompanyAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.Address1,
                "Address2": att.Address2,
                "Address3": att.Address3,
                "Address4": att.Address4,
                "PostCode": att.PostCode
            },
            "MultiContacts": multiplyFields(att, {
                "CustomFields": [{
                    "CustomFieldNo": 1,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                },
                {
                    "CustomFieldNo": 2,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                },
                {
                    "CustomFieldNo": 3,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                },
                {
                    "CustomFieldNo": 4,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue4'
                },
                {
                    "CustomFieldNo": 5,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue5'
                },
                {
                    "CustomFieldNo": 6,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                },
                {
                    "CustomFieldNo": 7,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue7'
                },
                {
                    "CustomFieldNo": 8,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue8'
                },
                {
                    "CustomFieldNo": 9,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue9'
                },
                {
                    "CustomFieldNo": 10,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue10'
                },
                ],
                "Firstname": 'att.mc1_Firstname',
                "Surname": 'att.mc1_Surname',
                "Guarantor": 'att.Guarantors',
                "IncludeInPIP": 'att.mc1_IncludeInPIP',
                "Address1": 'att.mc1_address1',
                "Address2": 'att.mc1_address2',
                "Address3": 'att.mc1_address3',
                "Address4": 'att.mc1_address4',
                "ForceDelete": 'att.mc1_ForceDelete',
                "PostCode": 'att.mc1_postcode',
                "DirectPhone": 'att.mc1_telephone',
                "Mobile": 'att.mc1_mobile',
                "Email": 'att.mc1_email',
                "Position": 'att.mc1_position',
                "DateOfBirth": 'att.mc1_DateOfBirth'
            },
            ),
            "CustomerTypes": [
                {
                    "Code": att.business_type
                 }
            ]
        },
        "ClientPassword": ""
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            dropLocalStorageData();
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        }
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });

}

export default {
    executeCreateNewProspectV2Peregrine
};
