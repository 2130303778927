// import { utils } from '@flipbyte/formik-json';
import _ from 'lodash';
import React from 'react';
import { changeHandler, joinNames } from '../utils0'
// import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import NumberFormat from 'react-number-format';

const CustomCurrency = ({ config, formik, value = '', error }) => {
    const {
        name,
        label,
        type,
        attributes,
        fieldType,
        defaultValue,
        icon,
        labelClass = '',
        tabIndex,
        fieldClass = 'form-control',
        formGroupClass = 'form-group'
    } = config;

    const { setFieldValue, handleChange, handleBlur } = formik;
    const isInputGroup = icon ? true : false;
    const currentValue = value;
    
    const numberMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        suffix: ''
      })

    return (
        <React.Fragment>
            
            <NumberFormat 
            
            
            id={ name }
            tabIndex={ (attributes && attributes.tabIndex) ? attributes.tabIndex : '' } 
            validation={ (attributes && attributes.validation) ? attributes.validation : '' } 
            name={ name }
            type={ fieldType }
            className={ fieldClass + ( error ? ' is-invalid ' : '' ) } 
            decimalScale={2} 
            value={ currentValue }
            onChange={ changeHandler.bind(this, handleChange, formik, config) }
            onBlur={ handleBlur }
        fixedDecimalScale={true}  
        placeholder=""
        guide={false}
    thousandSeparator={true}
              />

        </React.Fragment>
    );
}

export default CustomCurrency; 