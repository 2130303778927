import axios from 'axios';
import { config } from './../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from './../Utils'

const endpoint = "UpdateCustomer";
const endpoint2 = "UpdateCase";

export const executeUpdateCustomer = (att, applicant_id, customer_reference) => {
    
    let payload_1 = payload1(att, applicant_id, customer_reference);

    axios.post(config().base_url + endpoint, payload_1).then(function(response){


        let payload_2 = updateCasePayload(att, applicant_id, customer_reference);

        if(response.data.d.UpdateResult.SuccessCode==0) {

            axios.post(config().base_url + endpoint2, payload_2).then(function(response){

                if(response.data.d.UpdateResult.SuccessCode==0) {
                    dropLocalStorageData();
                    window.location.href = "/case/" + applicant_id;
                }

            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });

        }
        }).catch(function (error){
            console.log('caught error');
            console.log(error);
        });

}

const payload1 = (att, applicant_id, customer_reference) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "Customer": {
            "CustomerReference": customer_reference,
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "Firstname2": att.mc1_Firstname__1,
            "Surname2": att.mc1_Surname__1,
            "Email": att.compEmail,
            "Telephone": att.compTelephone,
            "Mobile": att.compMobile,
            "CustomerStatus": 'Prospect',
            // "B2BCustomer": "true",
            "IsUpdateCaseCustomer":true,
            "DateOfBirth": convertDateForApi(att.mc1_DateOfBirth),
            "InvoiceTermName": "",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.mc1_address1,
                "Address2": att.mc1_address2,
                "Address3": att.mc1_address3,
                "Address4": att.mc1_address4,
                "PostCode": att.mc1_postcode
            },
            "CompanyAddress": {
                "address1": att.compAddress1,
                "address2": att.compAddress2,
                "address3": att.compAddress3,
                "address4": att.compAddress4,
                "postcode": att.compPostcode
            },
            "MultiContacts": multiplyFields(att, {
                "CustomFields": [{
                    "CustomFieldNo": 1,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                },
                {
                    "CustomFieldNo": 2,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                },
                {
                    "CustomFieldNo": 3,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                },
                {
                    "CustomFieldNo": 6,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                }
                ],
                "Firstname": 'att.mc1_Firstname',
                "Surname": 'att.mc1_Surname',
                "Guarantor": 'att.Guarantors',
                "IncludeInPIP": 'att.mc1_IncludeInPIP',
                "Address1": 'att.mc1_address1',
                "Address2": 'att.mc1_address2',
                "Address3": 'att.mc1_address3',
                "Address4": 'att.mc1_address4',
                "ForceDelete": 'att.mc1_ForceDelete',
                "PostCode": 'att.mc1_postcode',
                "DirectPhone": 'att.mc1_telephone',
                "Mobile": 'att.mc1_mobile',
                "Email": 'att.mc1_email',
                "Position": 'att.mc1_position',
                "DateOfBirth": 'att.mc1_DateOfBirth'
            },

            )
                }
    }
}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "MainApplicant": {
                "Title": '',
                "Firstname": '',
                "Surname": '',
                "DateOfBirth": convertDateForApi(""),
                "Telephone": att.Telephone,
                "WorkTelephone": '',
                "Mobile": att.Mobile,
                "EmailAddress": att.Email,
                "CurrentAddress": {
                    "HouseName": '',
                    "HouseNumber": '',
                    "Address1": att.Address1,
                    "Address2": att.Address2,
                    "Address3": att.Address3,
                    "Address4": att.Address4,
                    "PostCode": att.Postcode
                },
                "PreviousAddresses": {},
                "App2ID": 0
            },
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "NetClaim": att.NetClaim,
            "Commission": att.Commission,
            "ClaimInterest": att.ClaimInterest,
            "CustomerInitialFee": att.CustomerInitialFee,
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": att.CustomerCompletionFee,
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi("") ,
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',

            // 
            "CaseOwner": "",
            "CustomerOwner": "",
            "CaseUnderWriter": att.UnderWriter,
            "CaseProcessor": att.Processor,
            "CaseAdditionalUser1": att.AdditionalUser1,
            "CaseAdditionalUser2": "",


            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.purpose
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],

            
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 
    for(let i=0; i<100; i++) {
        if(typeof att['SecuirtyCode__' + i] !=='undefined') {
            console.log(att['SecuirtyCode__' + i]);

            ret.CaseApp.SecurityAddress.push(
                {
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['secuirtyType__' + i],
                    "SecuirtyDetails": att['secuirtyDetails__' + i],
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(''),
                    "SecuirtyEquityValue": att['SecuirtyEquityValue__' + i],
                    "SecuirtyLendingValue": att['SecuirtyLendingValue__' + i],
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": convertDateForApi(''),
                    "SecuirtyMortgageValue": 0,
                    "SecuirtyMarketValue": Number(att['security_multiplier__' + i]),
                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

export default {
    executeUpdateCustomer
};
