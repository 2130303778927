import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData, parseNum, twoDecimals, getPaymentPlanDetailFormatted } from '../Utils'

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeCreateCaseJSONPeregrine = (att, applicant_id) => {

    
    /*
    let testpayload = getPaymentPlanDetailFormatted(att);
    console.log(testpayload);
    return false;
    */

    let payload_1 = createCasePayload(att, applicant_id);
  
    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            window.location.href = "/case/" + applicant_id;
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });
            
        }
    }).catch(function (error) {
        console.log('caught error');
        console.log(error);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    console.log('-----');
    console.log(att);

    att.rrp = convertToDouble(parseNum(att.rrp));
    att.net_price = convertToDouble( parseNum(att.net_price));


    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": customer_reference,
                "matterType": att.finance_type,
                "provider": att.funder,
                "providerProduct": 'Hire Purchase - Regulated',
                "LoanType": att.LoanType,
                "productCode": att.productCode,
                "providerRef": att.providerRef,
                "totalLoan": convertToDouble(att.amount_financed),
                "term": att.term,
                "termPeriod": "M",
                "InitialFee": convertToDouble(att.doc_fee),
                "ExitFee": convertToDouble(att.option_to_purchase_fee),
                "InterestYearly": att.yield,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "UserID": att.UserID,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": att.AdditionalUser2,
                "Team": att.Team,
                "source": att.source,
                "SourceEmail": att.SourceEmail,
                "IsSourceNeedToCreate": "false",
                "SourceAddress": {
                    "houseName": att.SourceAddress_houseName,
                    "houseNumber": att.SourceAddress_houseNumber,
                    "address1": att.SourceAddress_address1,
                    "address2": att.SourceAddress_address2,
                    "address3": att.SourceAddress_address3,
                    "address4": att.SourceAddress_address4,
                    "postcode": att.SourceAddress_postcode
                },
                "sourceref": att.sourceref,
                "moneyfor": att.moneyfor,
                "sourceAffiliate": att.sourceAffiliate,
                "sourceInitialFeePerc": att.sourceInitialFeePerc,
                "sourceInitialFee": att.sourceInitialFee,
                "affiliateInitialFeePerc": att.affiliateInitialFeePerc,
                "affiliateInitialFee": att.affiliateInitialFee,
                "ExitFeePerc": att.ExitFeePerc,
                // "ExitFee": convertToDouble(att.ExitFee),
                "InitialFeePerc": att.InitialFeePerc,
                // "InitialFee": convertToDouble(att.InitialFee),
                "caseStatus": att.caseStatus,
                "currentAddressYears": att.currentAddressYears,
                "currentAddressMonths": att.currentAddressMonths,
                "app1PreviousAddress": [{
                    "houseName": "",
                    "houseNumber": null,
                    "address1": att.prevaddress1,
                    "address2": att.prevaddress2,
                    "address3": att.prevaddress3,
                    "address4": att.prevaddress4,
                    "postcode": att.prevpostcode,
                    "timeInYears": null,
                    "timeInMonths": null
                }],
                "DynamicFieldDetail": [{
                    "FieldNo": 1,
                    "FieldValue": att.funding_category
                },
                {
                    "FieldNo": 2,
                    "FieldValue": att.dynamicData2
                },
                {
                    "FieldNo": 3,
                    "FieldValue": att.dynamicData3
                },
                {
                    "FieldNo": 4,
                    "FieldValue": att.rrp
                },
                {
                    "FieldNo": 5,
                    "FieldValue": att.net_price
                },
                {
                    "FieldNo": 6,
                    "FieldValue": att.plus_vat_1
                },
                {
                    "FieldNo": 7,
                    "FieldValue": att.not_vatable_cost
                },
                {
                    "FieldNo": 8,
                    "FieldValue": att.road_fund_licence
                },
                {
                    "FieldNo": 9,
                    "FieldValue": att.total_cost
                },
                {
                    "FieldNo": 10,
                    "FieldValue": att.part_exchange
                },
                {
                    "FieldNo": 11,
                    "FieldValue": att.plus_vat_2
                },
                {
                    "FieldNo": 12,
                    "FieldValue": att.part_exchange_vat
                },
                {
                    "FieldNo": 13,
                    "FieldValue": att.total_price
                },
                {
                    "FieldNo": 14,
                    "FieldValue": att.total_price
                },
                {
                    "FieldNo": 15,
                    "FieldValue": att.total_price
                },
                {
                    "FieldNo": 16,
                    "FieldValue": att.cash_deposit
                },
                {
                    "FieldNo": 17,
                    "FieldValue": att.dealer_deposit
                },
                {
                    "FieldNo": 18,
                    "FieldValue": att.settlement
                },
                {
                    "FieldNo": 19,
                    "FieldValue": att.vat_2
                },
                {
                    "FieldNo": 20,
                    "FieldValue": twoDecimals(parseNum(att.cash_deposit) + parseNum(att.total_price))
                },
                {
                    "FieldNo": 21,
                    "FieldValue": att.frequency
                },
                {
                    "FieldNo": 22,
                    "FieldValue": att.start_date
                },
                {
                    "FieldNo": 24,
                    "FieldValue": att.financecomp
                },
                {
                    "FieldNo": 25,
                    "FieldValue": att.agreementnumber
                },
                {
                    "FieldNo": 26,
                    "FieldValue": att.balloon
                },
                {
                    "FieldNo": 27,
                    "FieldValue": att.subsidy
                },
                {
                    "FieldNo": 28,
                    "FieldValue": att.subsidy_code
                },
                {
                    "FieldNo": 29,
                    "FieldValue": twoDecimals(att.amount_financed)
                },
                {
                    "FieldNo": 30,
                    "FieldValue": twoDecimals(att.interest_total)
                },
                {
                    "FieldNo": 31,
                    "FieldValue": att.payment_profile1
                },
                {
                    "FieldNo": 32,
                    "FieldValue": att.payment_profile2
                }
                ],
                "SecurityAddress": multiplyFields(att, {
                    "houseName": 'att.asset1_house_name',
                    "houseNumber": 'att.asset1_house_no',
                    "address1": 'att.asset1_addr1',
                    "address2": 'att.asset1_addr2',
                    "address3": 'att.asset1_addr3',
                    "address4": 'att.asset1_addr4',
                    "postcode": 'att.asset1_postal',
                    "CurrentValue": 'att.asset1_CurrentValue',
                    "SecuirtyType": 'att.asset1_securityType',
                    "SecuirtyDetails": 'att.asset1_securityDetails',
                    "SecuirtyCharge": 'att.asset1_SecurityCharge',
                    "SecurityTitleNumber": 'att.asset1_TitleNumber'
                }, 'SecurityAddress'),
                "customerDetails": {
                    "customerCode": customer_reference,
                    "b2B": 'true',
                    "IsUpdateCustomerDetail" : true,
                    "AlternativeSalutation": att.datest,
                    "title": att.title,
                    "firstName": att.main_firstname,
                    "middleName": "",
                    "surname": att.main_surname,
                    "houseName": att.houseName,
                    "houseNumber": att.houseNumber,
                    "address1": att.ba1,
                    "address2": att.ba2,
                    "address3": att.ba3,
                    "address4": att.city,
                    "postcode": att.postcode,
                    "Website" : att.companyreg,
                    "PassportNumber": att.vat_reg_no,
                    "CustomerGroup": att.nb,
                    "ClientStatus": "Prospect",
                    "source": att.source,
                    "SourceEmail": att.SourceEmail,
                    "IsSourceNeedToCreate": "false",
                    "SourceAddress": {
                        "houseName": att.SourceAddress_houseName,
                        "houseNumber": att.SourceAddress_houseNumber,
                        "address1": att.SourceAddress_address1,
                        "address2": att.SourceAddress_address2,
                        "address3": att.SourceAddress_address3,
                        "address4": att.SourceAddress_address4,
                        "postcode": att.SourceAddress_postcode
                    },
                    "CompanyAddress": {
                        "address1": att.ba1,
                        "address2": att.ba2,
                        "address3": att.ba3,
                        "address4": att.city,
                        "address5": att.county,
                        "postcode": att.postcode
                    },
                    "CustomFields": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": att.timeataddress
                        }
                    ],
                    "MultiContacts": multiplyFields(att, {
                        "CustomFields": [{
                            "CustomFieldNo": 1,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                        },
                        {
                            "CustomFieldNo": 2,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                        },
                        {
                            "CustomFieldNo": 3,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                        },
                        {
                            "CustomFieldNo": 4,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue4'
                        },
                        {
                            "CustomFieldNo": 5,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue5'
                        },
                        {
                            "CustomFieldNo": 6,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                        },
                        {
                            "CustomFieldNo": 7,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue7'
                        },
                        {
                            "CustomFieldNo": 8,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue8'
                        },
                        {
                            "CustomFieldNo": 9,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue9'
                        },
                        {
                            "CustomFieldNo": 10,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue10'
                        },
                        ],
                        "Firstname": 'att.mc1_Firstname',
                        "Surname": 'att.mc1_Surname',
                        // "Guarantor": 'att.mc1_Guarantor',
                        "Address1": 'att.mc1_address1',
                        "Address2": 'att.mc1_address2',
                        "Address3": 'att.mc1_address3',
                        "Address4": 'att.mc1_address4',
                        "PostCode": 'att.mc1_postcode',
                        "DirectPhone": 'att.mc1_telephone',
                        "Mobile": 'att.mc1_mobile',
                        "Email": 'att.mc1_email',
                        "Position": 'att.mc1_position',
                        "DateOfBirth": 'att.mc1_DateOfBirth'
                    }, 'MultiContacts'

                    ),
                    "telephone": att.telephone,
                    "mobile": att.mobile,
                    "dateofBirth": convertDateForApi(""),
                    "CompanyName": att.name,
                    "Company": att.name,
                    "email": att.email,
                    "loanAmount": att.loanAmount,
                    "applicants": null,
                    "othername": att.othername,
                    "ninumber": att.ninumber,
                    "ContactMethod": [
                        ""
                    ],
                    "notes": att.proposal_notes,
                    "ClientStatus": att.ClientStatus,
                    "IsDeduplicationReq": false,
                    "BankDetail": [{
                        "BankName": att.bank_name,
                        "AccountName": att.account_name,
                        "AccountNumber": att.account_number,
                        "SortCode": (att.sort_code + '').replace(/-/g, "")
                    }]
                },
                "PaymentPlanDetail": getPaymentPlanDetailFormatted(att),
                "EquipmentDetails": multiplyFields(att, {
                    "ID": "",
                    "ModelName": "att.model_name",
                    "ModelDescription": "att.description",
                    "Manufacturer": "att.manufacture",
                    "ManufacturerYear": "att.year_of_manufacture",
                    "ListPrice": "att.rrp",
                    "SalePrice": "att.net_price",
                    "Vat": "20",
                    "Condition": "att.condition",
                    "EquipmentCustomField": [
                        { "CustomFieldNo": "1", "CustomFieldValue": "att.sn" },
                        { "CustomFieldNo": "2", "CustomFieldValue": "att.reg_number" },
                        { "CustomFieldNo": "3", "CustomFieldValue": "att.date_of_registration" },
                        { "CustomFieldNo": "4", "CustomFieldValue": "att.current_miles"  },
                        { "CustomFieldNo": "5", "CustomFieldValue": "att.not_vatable_cost" },
                        { "CustomFieldNo": "6", "CustomFieldValue": "att.road_fund" },
                        { "CustomFieldNo": "7", "CustomFieldValue": "att.equipment"  },
                        { "CustomFieldNo": "8", "CustomFieldValue": "att.total_cost_2" },
                        { "CustomFieldNo": "9", "CustomFieldValue": "att.sup_name" },
                        { "CustomFieldNo": "10", "CustomFieldValue": "att.sup_phone" },
                        { "CustomFieldNo": "11", "CustomFieldValue": "att.sup_email" },
                        { "CustomFieldNo": "12", "CustomFieldValue": "att.sup_contact_name" },
                        { "CustomFieldNo": "13", "CustomFieldValue": "att.sup_business_address1" },
                        { "CustomFieldNo": "14", "CustomFieldValue": "att.sup_business_address2" },
                        { "CustomFieldNo": "15", "CustomFieldValue": "att.sup_business_address3" },
                        { "CustomFieldNo": "16", "CustomFieldValue": "att.sup_business_address4" },
                        { "CustomFieldNo": "17", "CustomFieldValue": "att.sup_postcode" },
                        { "CustomFieldNo": "18", "CustomFieldValue": "att.sup_mobile" },
                        { "CustomFieldNo": "19", "CustomFieldValue": "att.sup_reg_number" },
                        { "CustomFieldNo": "20", "CustomFieldValue": "att.vat" }
                    ],
                    "ForceDelete": null
                },'EquipmentDetails'),                
                "otherApplicantDetails": [
                    {
                        "applicantNumber": 1,
                        "title": null,
                        "firstName": "",
                        "middleName": null,
                        "surname": null,
                        "houseName": null,
                        "houseNumber": null,
                        "address1": null,
                        "address2": null,
                        "address3": null,
                        "address4": null,
                        "postcode": null,
                        "telephone": null,
                        "mobile": null,
                        "sex": null,
                        "dateofBirth": convertDateForApi(''),
                        "email": null,
                        "maritalStatus": null,
                        "currentAddressYears": null,
                        "currentAddressMonths": null,
                        "currentAddressEstimatedValue": null,
                        "currentAddressPurchasePrice": null,
                        "previousAddress": [
                            {
                                "houseName": null,
                                "houseNumber": null,
                                "address1": null,
                                "address2": null,
                                "address3": null,
                                "address4": null,
                                "postcode": null,
                                "timeInYears": null,
                                "timeInMonths": null
                            }
                        ],
                        "employmentDetails": {
                            "occupationType": null,
                            "employmentStatus": null,
                            "employerName": "",
                            "address1": "",
                            "address2": "",
                            "address3": "",
                            "address4": "",
                            "postcode": "",
                            "timeInYears": null,
                            "timeInMonths": null,
                            "firstIncome": null,
                            "secondIncome": null,
                            "otherIncomeDescription": null,
                            "partTimeHoursWorked": null
                        },
                        "previousEmploymentDetails": [
                            {
                                "occupationType": null,
                                "employmentStatus": null,
                                "employerName": "",
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null,
                                "firstIncome": null,
                                "secondIncome": null,
                                "otherIncomeDescription": null,
                                "partTimeHoursWorked": null
                            }
                        ]
                    }
                ]
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {

    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeCreateCaseJSONPeregrine
};
