import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { config } from '../Configurator'

class LoginBox extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '' 
        }
    }

    setEmail(v)
    {
		this.setState({
			email: v
		})
    }

    setPassword(v)
    {
		this.setState({
			password: v
		})
    }

    validateForm() {
        return this.email.length > 0 && this.password.length > 0;
      }
    
    handleSubmit() {
          // event.preventDefault();

          let base_url = config().base_url;
          base_url = base_url.replace('Webservices/BrightOfficeAPI.asmx/', 'CheckMails.asmx/');

          axios.post(base_url + 'LoginMobileV3', {
              "Options": {
                "Username": this.state.email,
                "Password": this.state.password,
                "OSName": "",
                "existingSessionKey": "",
                "deviceUniqueId": "",
                "softwareLoginScreenVersion": "",
                "OverrideLoginURL": "",
                "ScreenSize": ""
            }
            })
            .then(response => {
                if(response.status===200) {
                    localStorage.setItem("bo_email", this.state.email);
                    localStorage.setItem("bo_password", this.state.password);
                    this.props.history.push("/dash") //doing redirect here.
                    //console.log('redirecting');
                    //return <Redirect to='/dash' />
                }
            })
            .catch((error) => {
                alert("Invalid username or password");
            });
    
      }

    render() {
        // const { cases } = this.state;
        return (
            
            <div className="login-page">
            <div className="container">
            <div className="row">
                <div className="col-md-4 ml-auto mr-auto">
    
                <div className="text-center login-logo">
                    <img src={ config().logo } width="280" alt="Logo" />
                </div>
    
                <form>
                <FormGroup controlId="email">
                <label>Username</label>
                <FormControl
                    autoFocus
                    type="text"
                    value={this.email}
                    onChange={e => this.setEmail(e.target.value)}
                />
                </FormGroup>
                <FormGroup controlId="password">
                <label>Password</label>
                <FormControl
                    value={this.password}
                    onChange={e => this.setPassword(e.target.value)}
                    type="password"
                />
                </FormGroup>
                <Button onClick={this.handleSubmit.bind(this)}  block variant="secondary" type="button">
                Login
                </Button>
                <div className="mt-5 mb-5"></div>
              </form>
            </div>
          </div>
          </div>
             </div>
        )
    }

}

export default withRouter(LoginBox);