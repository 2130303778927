import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "UpdateCustomer";
const endpoint2 = "UpdateCase";
const endpoint3 = "GetCaseAssessorByCaseJSON";
const endpoint4 = "UpdateCaseAssessorJSON";

export const executeUpdateCustomerUpdateCase = (att, applicant_id, customer_reference) => {
    
    let payload_1 = payload1(att, applicant_id, customer_reference);

    // console.log(payload_1);
    // return false;

    // FIRST CALL (endpoint)
    axios.post(config().base_url + endpoint, payload_1).then(function(response){

        let payload_2 = updateCasePayload(att, applicant_id, customer_reference);

        if(response.data.d.UpdateResult.SuccessCode==0) {

            // SECOND CALL (endpoint2)
            axios.post(config().base_url + endpoint2, payload_2).then(function(response){

                if(response.data.d.UpdateResult.SuccessCode==0) {

                    // THIRD CALL (endpoint3)
                    axios.post(config().base_url + endpoint3, getCaseAssessorPayload(applicant_id)).then(function (response) {

                        if(response.data.d.EnquiryResult.SuccessCode==0) {

                            // FOURTH CALL (endpoint4)
                            // if everything okay update case assessor 
                            axios.post(config().base_url + endpoint4, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                                if(response.data.d.EnquiryResult.SuccessCode==0) {
                                    //if(att.form_id=='13') { // delay for sound to play 
                                    //    setTimeout(function(){ window.location.href = "/case/" + applicant_id;  }, 2800);
                                    //} else {
                                        dropLocalStorageData();
                                        window.location.href = "/case/" + applicant_id;
                                    //}
                                }
                            }).catch(function (error){
                                console.log('caught error');
                                console.log(error);
                            });
        
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });


                }

            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });

        }
        }).catch(function (error){
            console.log('caught error');
            console.log(error);
        });

}

const payload1 = (att, applicant_id, customer_reference) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "Customer": {
            "CustomerReference": customer_reference,
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "Firstname2": att.mc1_Firstname__1,
            "Surname2": att.mc1_Surname__1,
            "Email": att.Email,
            "Telephone": att.Telephone,
            "Mobile": att.Mobile,
            "CustomerStatus": "Prospect",
            // "B2BCustomer": "true",
            "IsUpdateCaseCustomer":true,
            "DateOfBirth": convertDateForApi(att.mc1_DateOfBirth),
            "InvoiceTermName": "",
            "CustomerAddress": {
                "HouseName": "",
                "HouseNumber": "",
                "Address1": att.Address1,
                "Address2": att.Address2,
                "Address3": att.Address3,
                "Address4": att.Address4,
                "PostCode": att.Postcode
            },
            "CompanyAddress": {
                "address1": att.compAddress1,
                "address2": att.compAddress2,
                "address3": att.compAddress3,
                "address4": att.compAddress4,
                "postcode": att.compPostcode
            },
            "MultiContacts": multiplyFields(att, {
                "CustomFields": [{
                    "CustomFieldNo": 1,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                },
                {
                    "CustomFieldNo": 2,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                },
                {
                    "CustomFieldNo": 3,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                },
                {
                    "CustomFieldNo": 6,
                    "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                }
                ],
                "Firstname": 'att.mc1_Firstname',
                "Surname": 'att.mc1_Surname',
                "Guarantor": 'att.Guarantors',
                "IncludeInPIP": 'att.mc1_IncludeInPIP',
                "Address1": 'att.mc1_address1',
                "Address2": 'att.mc1_address2',
                "Address3": 'att.mc1_address3',
                "Address4": 'att.mc1_address4',
                "PostCode": 'att.mc1_postcode',
                "DirectPhone": 'att.mc1_telephone',
                "Mobile": 'att.mc1_mobile',
                "Email": 'att.mc1_email',
                "ForceDelete": 'att.mc1_ForceDelete',
                "Position": 'att.mc1_position',
                "DateOfBirth": 'att.mc1_DateOfBirth'
            },

            )
                }
    }
}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "IsUpdateCaseCustomer":true,
            "MainApplicant": {
                "Title": '',
                "Firstname": att.FirstName,
                "Surname": att.Surname,
                "DateOfBirth": convertDateForApi(""),
                "Telephone": att.Telephone,
                "WorkTelephone": '',
                "Mobile": att.Mobile,
                "EmailAddress": att.Email,
                "CurrentAddress": {
                    "HouseName": '',
                    "HouseNumber": '',
                    "Address1": att.Address1,
                    "Address2": att.Address2,
                    "Address3": att.Address3,
                    "Address4": att.Address4,
                    "PostCode": att.Postcode
                },
                "PreviousAddresses": {},
                "App2ID": 0
            },
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "NetClaim": convertToDouble(att.NetClaim),
            "Term": att.Term,
            "InterestMonthly": att.InterestMonthly,
            "Commission": convertToDouble(att.Commission),
            "ClaimInterest": att.ClaimInterest,
            "CustomerInitialFee": convertToDouble(att.CustomerInitialFee),
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": convertToDouble(att.CustomerCompletionFee),
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi("") ,
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',

            // 
            "CaseOwner": "",
            "CustomerOwner": "",
            "CaseUnderWriter": att.UnderWriter,
            "CaseProcessor": att.Processor,
            "CaseAdditionalUser1": att.AdditionalUser1,
            "CaseAdditionalUser2": "",


            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.dynamicData2
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],

            
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 

    //console.log( att['SecuirtyCode__' + i]);
    console.log('attributes available:');
    console.log(att);

    for(let i=0; i<20; i++) {

        if(typeof att['SecurityType__' + i] !=='undefined' || typeof att['SecuirtyCode__' + i] !=='undefined') {


            ret.CaseApp.SecurityAddress.push(
                {
                    "_id": att['SecurityID__' + i],
                    "ForceDelete": (typeof(att['ForceDelete__' + i] != 'undefined') && att['ForceDelete__' + i]===true) ? true : false,
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": att['SecurityHouseName__' + i],
                    "HouseNumber": att['SecurityHouseNo__' + i],
                    "Address1": att['SecurityAddress1__' + i],
                    "Address2": att['SecurityAddress2__' + i],
                    "Address3": att['SecurityAddress3__' + i],
                    "Address4": att['SecurityAddress4__' + i],
                    "Postcode": att['SecurityPostcode__' + i],
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['SecurityType__' + i],
                    "SecuirtyDetails": att['SecurityDetails__' + i],
                    "SecuirtyCharge": att['SecurityCharge__' + i],
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(''),
                    

                    // "SecurtyPurchaseValue": 0,
                    // "SecuirtyPurchaseDate": convertDateForApi(''),
                   

                    "SecurityTitleNumber": att['SecurityTitle__' + i],
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}


export default {
    executeUpdateCustomerUpdateCase
};
